<template>
  <div
    class="w-full h-full px-44"
    :class="{ 'flex flex-col ': !pdf }"
    style=" padding-top: 40px;"
    :style="[showInfo ? {'position': 'fixed'} : {'position': 'inherit'} ]"
  >
    <div
      v-if="showInfo"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
    >
      <div
        class="bg-white p-10 rounded-md shadow-md overflow-y-auto popup"
        style="height: 60rem; width: 120rem; position: relative;"
      >
        <div class="relative">
          <button
            class="absolute top-0 right-0 p-5"
            style="position: sticky; z-index: 1; float: right;"
            @click="this.toggleShowInfo"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-14 h-14"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div class="overflow-y-auto">
            <div class="flex flex-col w-full h-full p-5">
              <div class="page-title">
                <h2>
                  {{ About.title_sub }}
                </h2>
              </div>
              <div>
                <p class="text-xl leading-snug mb-10">
                  {{ About.paragraph1_sub }}
                </p>
                <p
                  class="text-xl leading-snug mb-10"
                  v-html="About.paragraph2"
                />
                <p class="text-xl leading-snug mb-10">
                  {{ About.paragraph3 }}
                </p>
              </div>
            </div>
            <br>
            <ScalesPage class="scales" />
            <br>
            <GameAnalysisPage />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isDownloading"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
    >
      <button class=" btn btn-lg btn-circle btn-ghost loading btn-lg text-white" />
    </div>
    <chart-tooltip
      ref="tooltip"
      type="bar"
    />
    <bar-tooltip ref="barTooltip" />
    <div
      v-if="showHeader"
      class="flex justify-between items-center mb-10"
      style="border-bottom: 2px solid #e8e4ec; padding-bottom: 30px"
    >
      <router-link :to="{ name: 'Scoreboard', params: { campaignId, page: 0 } }">
        <div class="flex items-center text-sm font-semibold">
          <svg
            width="28"
            height="17"
            viewBox="0 0 33 22"
            fill="none"
            class="mr-3"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.458 10.9454L1 10.9454"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.999842 10.9437L10.9453 20.8892"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.999841 10.9454L10.9453 0.999963"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Scoreboard
        </div>
      </router-link>
      <div class="flex">
        <div
          v-if="this.showInfoButton"
          @click="this.toggleShowInfo"
          class="btn-primary mr-5"
          style="padding: 1rem !important;"
        >
          <label
            tabindex="0"
            class="cursor-pointer text-white flex items-center text-sm font-normal"
          > <svg
            fill="#fff"
            width="20"
            height="20"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-4"
          ><path d="M492.373 20.482v76.401c0 11.311 9.169 20.48 20.48 20.48s20.48-9.169 20.48-20.48V20.482c0-11.311-9.169-20.48-20.48-20.48s-20.48 9.169-20.48 20.48zm-269.704 92.457l38.195 66.161c5.655 9.796 18.18 13.152 27.976 7.497s13.152-18.18 7.497-27.976L258.142 92.46c-5.655-9.796-18.18-13.152-27.976-7.497s-13.152 18.18-7.497 27.976zM61.441 310.666l66.161 38.195c9.796 5.655 22.321 2.299 27.976-7.497s2.299-22.321-7.497-27.976L81.92 275.193c-9.796-5.655-22.321-2.299-27.976 7.497s-2.299 22.321 7.497 27.976zm836.66 38.2l66.161-38.195c9.796-5.655 13.152-18.18 7.497-27.976s-18.18-13.152-27.976-7.497l-66.161 38.195c-9.796 5.655-13.152 18.18-7.497 27.976s18.18 13.152 27.976 7.497zM764.837 179.103l38.195-66.161c5.655-9.796 2.299-22.321-7.497-27.976s-22.321-2.299-27.976 7.497l-38.195 66.161c-5.655 9.796-2.299 22.321 7.497 27.976s22.321 2.299 27.976-7.497zM619.705 609.548a20.479 20.479 0 019.029-16.979c53.847-36.315 86.469-95.279 86.469-159.497 0-107.392-90.442-194.744-202.353-194.744s-202.353 87.353-202.353 194.744c0 64.218 32.623 123.181 86.469 159.497a20.48 20.48 0 019.029 16.979v181.903c0 22.143 17.946 40.09 40.09 40.09h133.54c22.134 0 40.079-17.947 40.079-40.09V609.548zm40.96 181.904c0 44.763-36.283 81.05-81.039 81.05h-133.54c-44.765 0-81.05-36.285-81.05-81.05V620.14c-59.555-44.178-95.498-112.657-95.498-187.067 0-130.338 109.092-235.704 243.313-235.704s243.313 105.366 243.313 235.704c0 74.41-35.943 142.89-95.498 187.067v171.312zM410.986 949.054h203.735c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48H410.986c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48zm25.467 76.4h152.801c11.311 0 20.48-9.169 20.48-20.48s-9.169-20.48-20.48-20.48H436.453c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48z" /></svg>
            Understand report</label>
        </div>
        <div
          v-if="showDownloadButton"
          class="dropdown dropdown-bottom dropdown-end btn-primary"
          style="padding: 1rem !important;"
        >
          <label
            tabindex="0"
            class="cursor-pointer text-white flex items-center text-sm font-normal"
          >
            <svg
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke-width="1.2"
              class="mr-4"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
              />
            </svg>
            More</label>
          <ul
            tabindex="0"
            class="p-4 shadow dropdown-content menu bg-base-100 rounded-box w-96 border border-gray-300 text-black"
          >
            <li
              @click="downloadSubReport(false, reportType)"
              class="text-sm text-left leading-snug"
            >
              <a><svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mr-2 w-7 h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
                Download report</a>
            </li>
            <li
              @click="downloadSubReport(true, reportType)"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
                Download report (anonymized)</a>
            </li>
            <li
              @click="showShareModal = true"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
                Share with email</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-full h-full">
      <div class="page-title mb-8 flex justify-between items-baseline">
        <h2>
          {{ Subreports[reportType].pageTitle }}
        </h2>
      </div>
      <div v-if="showFullDescription">
        <br>
        <h4 class="text-xl font-semibold">
          {{ About.title }}
        </h4>
        <br>
        <p
          class="text-xl leading-snug mb-10"
        >
          {{ Subreports[reportType].description1 }}
        </p>
        <ul class="text-xl leading-snug mb-10">
          <li class="list-disc ml-10">
            {{ Subreports[reportType].bullet1 }}
          </li>
          <li class="list-disc ml-10">
            {{ Subreports[reportType].bullet2 }}
          </li>
          <li class="list-disc ml-10">
            {{ Subreports[reportType].bullet3 }}
          </li>
          <li
            v-show="Subreports[reportType].bullet4"
            class="list-disc ml-10"
          >
            {{ Subreports[reportType].bullet4 }}
          </li>
        </ul>
        <p
          class="text-xl leading-snug mb-10"
        >
          {{ Subreports[reportType].description2 }}
        </p>
        <div v-if="reportType === 'master_control_report'">
          <p
            class="text-xl leading-snug mb-10"
          >
            {{ Subreports[reportType].description3 }}
          </p>
          <ul class="text-xl leading-snug mb-10">
            <li class="list-disc ml-10">
              {{ Subreports[reportType].bullet5 }}
            </li>
            <li class="list-disc ml-10">
              {{ Subreports[reportType].bullet6 }}
            </li>
            <li class="list-disc ml-10">
              {{ Subreports[reportType].bullet7 }}
            </li>
            <li
              v-show="Subreports[reportType].bullet8"
              class="list-disc ml-10"
            >
              {{ Subreports[reportType].bullet8 }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <show-details-btn
      v-if="!pdf"
      class="flex justify-end pb-10"
      style="padding-right: 24px"
      v-model="showFullDescription"
      :is-sub-report-top-description="true"
      :main="true"
    />
    <show-details-btn
      v-if="!pdf"
      class="flex justify-end pb-3"
      v-model="showCandidateDetails"
      :is-sub-report-candidate-details="true"
      :main="true"
    />

    <div
      v-if="showCandidateDetails"
      class="w-full h-full"
      style="page-break-before: always;"
    >
      <hr
        class="border-0"
        style="background-color: #e8e4ec; height: 2px;"
      >

      <div class="flex justify-between">
        <div
          style="width: 48%"
        >
          <h4 class="text-xl font-semibold mt-12">
            Candidate information
          </h4>
          <div
            style="border-radius: 5px;
            padding: 20px 20px;
            background-color: white;
            border-left: 19px solid #46634F;
            border-top: 1px solid #46634F;
            border-right: 1px solid #46634F;
            border-bottom: 1px solid #46634F;
            margin-top: 10rem"
          >
            <div>
              <p><strong class="font-medium">{{ candidate.name }}</strong></p>
              <p><strong class="font-medium">Age: </strong>{{ candidate.current_age }}</p>
              <p><strong class="font-medium">Gender: </strong>{{ candidate.gender }}</p>
              <p><strong class="font-medium">Candidate ID: </strong>{{ candidate.alt_id }}</p>
            </div>
          </div>
        </div>
        <div
          style="width: 49%;"
        >
          <candidate-state
            :is-pdf="pdf"
            :is-sub-report="true"
            :main-candidate="{mood_level: candidate.mood_level, motivation_level: candidate.motivationLevel, sleep_duration: candidate.sleep_duration, tiredness_level: candidate.tiredness_level, undisturbedEnvironment: candidate.undisturbedEnvironment}"
          />
        </div>
      </div>
    </div>

    <div
      class=" flex justify-evenly"
      style="page-break-before: always;"
      :style="{ marginTop: this.pdf && !this.anonymized ? '50px' : '0px' }"
    >
      <div
        style="width: 50%;"
        class="mr-10 pt-10"
      >
        <div
          style="background: #46634F; border-radius: 10px 10px 0px 0px;"
          class="flex justify-between max-w-full overflow-x-auto"
        >
          <div class="flex justify-between">
            <h4 class="text-xl font-medium m-4 text-white flex">
              Cognitive Capacity & Self-rating
            </h4>
          </div>
          <show-details-btn
            v-if="!pdf"
            class="text-white pt-4 pr-5"
            v-model="showDescription1"
            :is-sub-report-description="true"
            :main="true"
          />
        </div>

        <p
          v-if="showDescription1"
          class="py-4 p-4"
        >
          Below is a description of the cognitive capacity score as well as the self estimated performance. Over/Underestimation may indicate a need to adjust the self estimation.
        </p>

        <div
          class="my-8 py-4 p-4"
        >
          <div
            class="py-4 p-4 w-full"
          >
            <div
              v-for="result, index in sortedData"
              :key="result.label"
            >
              <div
                class="flex justify-between font-medium"
              >
                <div>
                  {{ result.label }}
                </div>

                <div
                  class=" flex justify-end"
                  v-if="index === 0"
                >
                  <div class="pr-7">
                    Capacity
                  </div>

                  <div
                    class="tooltip inline-flex tendency items-center"
                    :data-tip="`Tendency: ${scoreboardContent.awarenessTranslations[candidate.awareness]}`"
                  >
                    Self-rating
                  </div>
                </div>
              </div>
              <div class="flex justify-between py-8">
                <bar-chart
                  :height="30"
                  :chart-data="{ labels: [result.label], datasets: [{ label: 'The Candidate', data: [result.stanine], backgroundColor: result.color }] }"
                  :options="smallBarChartOptions"
                />
                <div class="flex">
                  <div
                    class="pr-7"
                    style="width: 68px"
                  >
                    {{ result.stanine }}
                  </div>
                  <div
                    class=""
                    style="width: 96px"
                  >
                    {{ result.comparativePerformanceLevel }}
                  </div>
                </div>
              </div>
              <p class="py-4 p-4">
                {{ displayText(result) }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="pdf && Subreports[reportType].isMasterControl"
          style="page-break-before: always;"
        >
          <PerformanceUnderPressure
            :pdf="pdf"
            :anonymized="anonymized"
            :report-type="reportType"
            :main-measure1="mainMeasure1"
            :main-measure2="mainMeasure2"
            :chart-options-seperate="chartOptionsSeperate"
            :subreports="Subreports"
            :selected-report="selectedReport"
          />
        </div>
      </div>

      <div
        v-if="!pdf"
        class="line"
        style="height:; border-left: 2px solid #e8e4ec;"
      />

      <div
        style="width: 50%;"
        class="ml-10"
      >
        <div
          class="my-2 py-8 p-4  max-w-full overflow-x-auto"
        >
          <div
            class="px-4 w-full"
            v-if="Subreports[reportType].isMasterControl"
          >
            <div
              style="background: #46634F; border-radius: 10px 10px 0px 0px;"
              class="flex justify-between max-w-full overflow-x-auto"
            >
              <div class="flex justify-between">
                <h4 class="text-xl font-medium m-4 text-white flex">
                  Quality measures
                </h4>
              </div>
              <show-details-btn
                v-if="!pdf"
                class="text-white pt-4 pr-5"
                v-model="showDescription2"
                :is-sub-report-description="true"
                :main="true"
              />
            </div>
            <p
              v-if="showDescription2"
              class="py-4 p-4"
            >
              The quality measures are sub- or process measures describing how the main score has been achieved. They display the balance between speed, accuracy and rule focus.
              The dynamic between these variables that can to some extent be controlled by the individual given awareness.
            </p>
            <div class="my-14">
              <div
                v-for="result, index in qualityMeasures"
                :key="result.label"
              >
                <div
                  class="flex justify-between font-medium"
                >
                  <div>
                    {{ result.label }}  {{ result.label === 'Accuracy' ? 'In Impulse Control' : 'In Creativity & Cognitive Flexibility' }}
                  </div>

                  <div
                    class="flex justify-end"
                    v-if="index === 0"
                  >
                    <div class="pr-7">
                      Capacity
                    </div>
                  </div>
                </div>
                <div class="flex justify-between py-8">
                  <bar-chart
                    v-if="loadedTooltip"
                    :height="30"
                    :chart-data="{ labels: [result.label], datasets: [{ label: 'The Candidate', data: [result.stanine], backgroundColor: 'black' }] }"
                    :options="qualityMeasuresOptions"
                    :plugins="[$refs.tooltip.plugin]"
                  />

                  <div
                    class="pr-7"
                    style="width: 68px"
                  >
                    {{ result.stanine }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!pdf || (pdf && !Subreports[reportType].isMasterControl)"
            style="page-break-before: always;"
          >
            <PerformanceUnderPressure
              :pdf="pdf"
              :anonymized="anonymized"
              :report-type="reportType"
              :main-measure1="mainMeasure1"
              :main-measure2="mainMeasure2"
              :chart-options-seperate="chartOptionsSeperate"
              :subreports="Subreports"
              :selected-report="selectedReport"
            />
          </div>
        </div>
      </div>
    </div>
    <share-modal
      :open="showShareModal"
      :candidate="candidate"
      @close="showShareModal = false"
      :campaign-id="campaignId"
    />
  </div>
</template>
<script>

import { Subreports, About, CandidateInfo, QualityMeasures, Scoreboard, Charts, SelfAwareness, PerformanceComplexity, CognitiveProfileStrengths } from '@/content/reportContent.json'
import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import CandidateState from '@/components/reports/CandidateState.vue'
import { parseMainMeasures, parseEasyHardMeasures } from '@/utils/chartUtils'
import { BarChart } from 'vue-chart-3'
import ChartTooltip from '@/modules/AppReport/components/ChartTooltip.vue'
import BarTooltip from '@/modules/AppReport/components/BarTooltip.vue'
import ScalesPage from '@/modules/AppReport/components/ScalesPage.vue'
import GameAnalysisPage from '@/modules/AppReport/components/GameAnalysisPage.vue'
import ShareModal from '@/modules/AppReport/components/ShareModal.vue'
import PerformanceUnderPressure from '../components/PerformanceUnderPressure.vue'

import { impulseControlColor, creativityCognitiveFlexibilityColor, shortTermMemoryColor, strategicThinkingColor, attentionColor, estimateColor, conceptualizationColor, workingMemoryColor } from '@/modules/AppReport/chartConfig'

import download from 'downloadjs'

const barColors = [
  attentionColor,
  attentionColor,
  shortTermMemoryColor,
  workingMemoryColor,
  impulseControlColor,
  creativityCognitiveFlexibilityColor,
  conceptualizationColor,
  strategicThinkingColor
]

export default {
  components: {
    BarChart,
    ShowDetailsBtn,
    CandidateState,
    BarTooltip,
    ChartTooltip,
    ScalesPage,
    GameAnalysisPage,
    ShareModal,
    PerformanceUnderPressure
  },

  props: {
    campaignId: { type: String, required: true },
    userId: { type: String, required: true },
    pdf: { type: Boolean, default: false },
    reportType: { type: String, required: true },
    anonymized: { type: Boolean, default: false }
  },

  data () {
    return {
      scoreboardContent: Scoreboard,
      Charts,
      Subreports,
      About,
      SelfAwareness,
      QualityMeasures,
      mainMeasure1: '',
      mainMeasure2: '',
      content: PerformanceComplexity,
      CandidateInfo,
      CognitiveProfileStrengths,
      pupChartData: {},
      isDownloading: false,
      showHeader: true,
      showInfo: false,
      showInfoButton: true,
      showFullDescription: false,
      showCandidateDetails: false,
      showDescription1: false,
      showDescription2: false,
      showDescription3: false,
      showDownloadButton: true,
      showCandidateStatus: false,
      showShareModal: false,
      loadedTooltip: false,
      sortedData: [],
      qualityMeasures: [],
      candidate: null,
      state: 0,
      strongest: [],
      challenges: [],
      development: [],
      missingMeasures: false,
      showExternalToolip: false,
      showTooltipPos: false,
      chartWidth: 180,
      chartHeight: this.pdf ? 500 : 400,
      measureTooltipStyle: {
        left: 0,
        top: 0
      },
      datasetOptions: {
        categoryPercentage: 1,
        barPercentage: 0.95
      },
      behavioralExpressions: [],
      qualityMeasuresOptions: {
        responsive: false,
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        maintainAspectRatio: false,
        barThickness: 200,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: null
          }
        },
        scales: {
          x: {
            reverse: false,
            display: false,
            max: 9,
            grid: {
              display: false,
              drawBorder: false
            }
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        }
      },

      chartOptionsSeperate: {
        interaction: {
          mode: 'interpolate',
          intersect: false,
          axis: 'x'
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: this.externalTooltipHandler
          },
          title: {
            display: false,
            position: 'bottom',
            font: {
              size: 16,
              style: 'normal',
              weight: 400

            },
            color: 'black'
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            font: {
              size: 14
            }
          }
        },
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              z: 1,
              mirror: true,
              font: {
                size: 14
              },
              color: 'white',
              callback: function (value) {
                const label = this.getLabelForValue(value)
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = `${split[0]} &`
                  return split
                }
                return label
              }
            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false
            },
            beginAtZero: true,
            max: 11,
            ticks: {
              display: false
            }
          }
        },
        datasets: {
          line: {
            pointRadius: 4,
            pointBackgroundColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            },
            borderColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            }
          }
        }
      },

      smallBarChartOptions: {
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        responsive: false,
        maintainAspectRatio: false,
        barThickness: 200,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: null
          }
        },
        scales: {
          x: {
            reverse: false,
            display: false,
            max: 9,
            grid: {
              display: false,
              drawBorder: false
            }
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        }
      }
    }
  },

  async mounted () {
    this.showInfo = String(this.$route.query.showInfo).toLowerCase() === 'true'

    if (this.pdf) {
      this.showFullDescription = true
      this.showDescription1 = true
      this.showDescription2 = true
      this.showDescription3 = true
      this.showCandidateDetails = !this.anonymized
      this.showHeader = false
      this.showCandidateStatus = true
    }

    // get data
    this.reportType = this.$route.query.reportType
    const data = await this.$api.getSubReport(this.campaignId, this.userId, this.reportType)

    this.candidate = data.summarized

    this.mainMeasure1 = this.Subreports[this.reportType].mainMeasure1
    this.mainMeasure2 = this.Subreports[this.reportType].mainMeasure2

    const parsedMeasure = parseMainMeasures(data.measureScores).slice()
    this.sortedData = parsedMeasure

    this.sortedData.forEach((el, idx) => {
      el.color = barColors[idx]
    })

    this.pupChartData = parseEasyHardMeasures(data.measureScores)
    this.behavioralExpressions = (data.behavioralExpressions)

    const isMasterControl = (this.reportType === 'master_control_report')

    if (isMasterControl) {
      this.qualityMeasures = data.measureScores
        .filter(result => Charts.QualityMeasures[result.measure])
        .map(result => ({ stanine: result.stanine, label: Charts.QualityMeasures[result.measure].label }))

      this.qualityMeasures.sort((a, b) => parseInt(b.stanine) - parseInt(a.stanine))
    }

    this.sortedData = this.sortedData.filter(el => el.label === this.mainMeasure1 || el.label === this.mainMeasure2)
    this.sortedData.sort((a, b) => parseInt(b.stanine) - parseInt(a.stanine))

    this.$nextTick(() => {
      this.loadedTooltip = true
      this.qualityMeasuresOptions.plugins.tooltip.external = this.$refs.barTooltip.tooltipHandler
    })
  },

  methods: {
    createChartOptions (title) {
      return {
        interaction: {
          mode: 'interpolate',
          intersect: false,
          axis: 'x'
        },
        plugins: {
          tooltip: {
            enabled: false,
            displayColors: false,
            external: this.externalTooltipHandler
          },
          title: {
            display: true,
            position: 'top',
            font: {
              size: 18,
              style: 'normal',
              weight: 300

            },
            text: title,
            color: 'black'
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            font: {
              size: 14
            }
          }

        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            ticks: {
              display: false

            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false
            },
            beginAtZero: true,
            max: 9,
            ticks: {
              display: false
            }
          }
        },
        datasets: {
          line: {
            pointRadius: 2,
            borderWidth: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 3
              }
              return 0
            },
            borderColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            }
          }
        }
      }
    },
    createDatasetPup (key) {
      const colors = {
        impulseControl: impulseControlColor,
        creativityCognitiveFlexibility: creativityCognitiveFlexibilityColor,
        memory: shortTermMemoryColor,
        strategicThinking: strategicThinkingColor,
        attention: attentionColor
      }

      if (!this.pupChartData[key]) {
        this.missingMeasures = true

        return [
          {
            data: [0, 0],
            backgroundColor: colors[key],
            ...this.datasetOptions,
            order: 1

          },
          {
            data: [0, 0],
            borderColor: estimateColor,
            type: 'line',
            datalabels: {
              display: false
            },
            order: 0
          }
        ]
      } else {
        return [
          {
            data: [this.pupChartData[key].easy, this.pupChartData[key].hard],
            backgroundColor: colors[key],
            ...this.datasetOptions,
            order: 1
          },
          {
            data: [this.pupChartData[key].easy, this.pupChartData[key].hard],
            type: 'line',
            datalabels: {
              display: false
            },
            order: 0
          }
        ]
      }
    },
    displayText (result) {
      let index = 0
      if (result.stanine >= 7) {
        index = 2
      } else if (result.stanine >= 4) {
        index = 1
      }
      return this.CognitiveProfileStrengths.abilityLevels[result.label][index]
    },
    async downloadSubReport (anonymized, type) {
      this.isDownloading = true
      const { data, headers } = await this.$api.getPdfSubReport(this.userId, this.campaignId, anonymized, type)
      const contentType = headers['content-type']
      download(data, `Subreport ${type} ${anonymized ? `Candidate ${this.candidate.alt_id}` : this.candidate.name}`, contentType)

      this.isDownloading = false
      this.showDownloadButton = true
    },
    selectedReport (isSecondChart) {
      switch (this.reportType) {
        case 'master_control_report':
          return isSecondChart ? this.creativityCognitiveFlexibility : this.impulseControl
        case 'execute_report':
          return this.strategicThinking
        case 'gather_process_report':
          return this.attention
        case 'active_sketchpad_report':
          return this.memory
        default:
          return this.creativityCognitiveFlexibility
      }
    },
    toggleShowInfo () {
      this.showInfo = !this.showInfo
      if (this.$route.query.showInfo === 'true') {
        const currentQueryParams = { ...this.$route.query }
        currentQueryParams.showInfo = false
        this.$router.replace({
          query: currentQueryParams
        })
      }
    }
  },

  computed: {
    // MONITOR & CONTROL REPORT
    impulseControl () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDatasetPup('impulseControl'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'impulse_control_easy')
      }
    },
    creativityCognitiveFlexibility () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDatasetPup('creativityCognitiveFlexibility'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'creativity_cognitive_flexibility_easy')

      }
    },

    // ENVISION REPORT
    strategicThinking () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDatasetPup('strategicThinking'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'strategic_thinking_easy')

      }
    },

    // GATHER & PROCESS REPORT
    attention () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDatasetPup('attention'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'simple_attention')
      }
    },

    // ACTIVE SKETCHPAD REPORT
    memory () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDatasetPup('memory'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'short_term_memory')
      }
    },

    average () {
      const diffs = []
      const datasets = [this.impulseControl, this.creativityCognitiveFlexibility]
      datasets.forEach(data => {
        if (data) {
          const dataset = data.datasets[0]
          // DEMANDING score / EASY score - 1
          diffs.push(dataset.data[1] / dataset.data[0] - 1)
        }
      })
      const sum = diffs.reduce((total, current, index, arr) => total + current, 0)
      return Math.round(100 * sum / diffs.length)
    },
    level () {
      function score (diff) {
        if (Math.abs(diff) >= 1) {
          return Math.sign(diff)
        } else {
          return 0
        }
      }
      const diffs = []

      const datasets = [this.impulseControl || null, this.creativityCognitiveFlexibility || null]
      datasets.forEach(data => {
        if (data) {
          const dataset = data.datasets[0]
          // DEMANDING score - EASY score
          diffs.push(dataset.data[1] - dataset.data[0])
        }
      })
      const scores = diffs.map(score)
      const sum = scores.reduce((total, current, index, arr) => total + current, 0)
      let level
      if (sum === 4 || sum === 5) {
        level = 5
      } else if (sum === 2 || sum === 3) {
        level = 4
      } else if (sum === 1 || sum === 0 || sum === -1) {
        level = 3
      } else if (sum === -2 || sum === -3) {
        level = 2
      } else if (sum === -4 || sum === -5) {
        level = 1
      } else {
        level = 3
      }
      return level
    }
  }
}

</script>

<style>

div.popup h2 {
  padding-bottom: 20px;
}

div.popup div.scales img {
  width: 500px;
}

.tooltip.tendency::before {
  font-size: 10px;
}
</style>
