<template>
  <div
    class="pt-4"
    :style="{
      marginTop: pdf && !anonymized && reportType === 'master_control_report' ? '50px' : '0px',
      paddingTop: pdf && anonymized && reportType === 'master_control_report' ? '50px' : '0px'
    }"
  >
    <div
      style="background: #46634F; border-radius: 10px 10px 0px 0px; page-break-before: always;"
      class="flex justify-between max-w-full overflow-x-auto"
    >
      <div class="flex justify-between">
        <h4 class="text-xl font-medium m-4 text-white flex">
          Performance under pressure
        </h4>
      </div>
      <show-details-btn
        v-if="!pdf"
        class="text-white pt-4 pr-5"
        v-model="showDescription3"
        :is-sub-report-description="true"
        :main="true"
      />
    </div>
    <p
      v-if="showDescription3"
      class="py-4 p-4"
    >
      By introducing time pressure and increasing complexity we can see the variation in capacity. This variation signals how the individual will react and perform in situations when
      these specific abilities are put to the test.
    </p>
    <div class="flex justify-evenly pt-10 max-w-full overflow-x-auto">
      <div>
        <div
          class="flex justify-center font-medium"
        >
          {{ mainMeasure1 }}
        </div>
        <div
          style="width: 280px"
          class="flex justify-center"
        >
          <bar-chart
            style="width: 200px"
            :height="450"
            :chart-data="selectedReport(false)"
            :options="chartOptionsSeperate"
          />
        </div>
        <p
          v-if="selectedReport(false).behavioralExpression"
          style="width: 280px"
        >
          {{ selectedReport(false).behavioralExpression.description }}
        </p>
      </div>

      <div v-if="subreports[reportType].isMasterControl">
        <div
          class="flex justify-center font-medium"
        >
          {{ mainMeasure2 }}
        </div>
        <div
          style="width: 280px"
          class="flex justify-center"
        >
          <bar-chart
            style="width: 200px;"
            :height="450"
            :chart-data="selectedReport(true)"
            :options="chartOptionsSeperate"
          />
        </div>
        <p
          v-if="selectedReport(true).behavioralExpression"
          style="width: 280px"
          class="py-4 p-4"
        >
          {{ selectedReport(true).behavioralExpression.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>

import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import { BarChart } from 'vue-chart-3'

export default {
  components: {
    BarChart,
    ShowDetailsBtn
    // BarTooltip,
    // ChartTooltip
  },

  props: {
    pdf: {
      type: Boolean,
      required: true
    },
    anonymized: {
      type: Boolean,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    mainMeasure1: {
      type: String,
      required: true
    },
    mainMeasure2: {
      type: String,
      required: true
    },
    chartOptionsSeperate: {
      type: Object,
      required: true
    },
    subreports: {
      type: Object,
      required: true
    },
    selectedReport: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      showDescription3: false
    }
  }
}
</script>
